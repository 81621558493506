import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import Loading from "./components/Loading";

const SpecCate = lazy(() => import("./containers/SpecCate"));
const Spec = lazy(() => import("./containers/Spec"));
const SpecValue = lazy(() => import("./containers/SpecValue"));
const DefaultForm = lazy(() => import("./containers/DefaultForm"));
const Product = lazy(() => import("./containers/Product"));
const Login = lazy(() => import("./containers/Login"));
const ShortenLink = lazy(() => import("./containers/ShortenLink"));

export const dynamicPage = (page: string) =>
  ({
    specCate: <SpecCate />,
    spec: <Spec />,
    specValue: <SpecValue />,
    defaultForm: <DefaultForm />,
    product: <Product />,
    shortenLink: <ShortenLink />,
  }[page]);

export const routes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route
        path="/login"
        element={
          <Suspense fallback={<Loading />}>
            <Login />
          </Suspense>
        }
      />
    </Routes>
  </BrowserRouter>
);

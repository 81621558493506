export const upperCaseFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const convertListId = (arrayObj: any[]): string[] => {
  return arrayObj.map((e) => e.id || e);
};

export const removeEmptyKey = <T>(obj: T): T => {
  for (const key in obj) {
    if (!obj[key]) delete obj[key];
  }

  return obj;
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { removeEmptyKey } from "src/utils";
import { toast } from "react-toastify";
import api from "src/api";
import { List } from "src/types/common";
import {
  DefaultForm,
  DefaultFormQuery,
  PayLoadCreateDefaultForm,
  PayLoadUpdateDefaultForm,
} from "src/types/default-form";

export const getListDefaultForm = createAsyncThunk(
  `defaultForm/getListDefaultForm`,
  async (query: DefaultFormQuery): Promise<List<DefaultForm> | null> => {
    try {
      const { page = 1, pageLength = 10, ...rest } = query;
      const { data } = await api.get<List<DefaultForm>>("/default-forms", {
        params: {
          page,
          pageLength,
          ...removeEmptyKey(rest),
        },
      });
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const getDetailDefaultForm = createAsyncThunk(
  `defaultForm/getDetailDefaultForm`,
  async (id: string): Promise<DefaultForm | null> => {
    try {
      const { data } = await api.get<DefaultForm>(`/default-forms/${id}`);
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const updateForm = createAsyncThunk(
  `defaultForm/updateForm`,
  async (payload: PayLoadUpdateDefaultForm): Promise<DefaultForm | null> => {
    try {
      const { data } = await api.put<DefaultForm>("/default-forms", payload);
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const createForm = createAsyncThunk(
  `defaultForm/createForm`,
  async (payload: PayLoadCreateDefaultForm): Promise<DefaultForm | null> => {
    try {
      const { data } = await api.post<DefaultForm>("/default-forms", payload);
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const deleteForm = createAsyncThunk(
  `defaultForm/deleteForm`,
  async (id: string): Promise<string | null> => {
    try {
      await api.delete(`/default-forms/${id}`);
      return id;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

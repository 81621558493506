import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { SpecCate, SpecCateState } from "@src/types/specCate";
import { List } from "src/types/common";
import {
  createSpecCateRequest,
  deleteSpecCate,
  getListSpecCate,
} from "./action";

const initialState = {
  loading: false,
  listSpecCate: [],
  totalPage: 0,
  currentCate: null,
} as SpecCateState;

const specCateSlice = createSlice({
  name: "specCate",
  initialState,
  reducers: {
    selectCate: (
      state: SpecCateState,
      action: PayloadAction<SpecCate | undefined | null>
    ) => {
      return {
        ...state,
        currentCate: action.payload,
      };
    },
    updateSpecCate: (
      state: SpecCateState,
      action: PayloadAction<Partial<SpecCate>>
    ) => {
      return {
        ...state,
        listSpecCate: state.listSpecCate.map((cate: SpecCate) => {
          const { id } = action.payload;
          if (cate.id !== id) return cate;
          return { ...cate, ...action.payload };
        }),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListSpecCate.pending, (state: SpecCateState) => {
      state.loading = true;
    });
    builder.addCase(
      getListSpecCate.fulfilled,
      (state: SpecCateState, action: PayloadAction<List<SpecCate> | null>) => {
        state.loading = false;
        const { rows = [], totalPage = 0 } = action.payload || {};
        state.listSpecCate = rows;
        state.totalPage = totalPage;
      }
    );
    builder.addCase(getListSpecCate.rejected, (state: SpecCateState) => {
      state.loading = false;
    });
    builder.addCase(
      createSpecCateRequest.fulfilled,
      (state: SpecCateState, action: PayloadAction<SpecCate | null>) => {
        if (!action.payload) return state;
        return {
          ...state,
          listSpecCate: [...state.listSpecCate, action.payload],
        };
      }
    );
    builder.addCase(
      deleteSpecCate.fulfilled,
      (state: SpecCateState, action: PayloadAction<string | null>) => {
        if (!action.payload) return state;
        return {
          ...state,
          listSpecCate: state.listSpecCate.filter(
            (cate) => cate.id !== action.payload
          ),
        };
      }
    );
  },
});

export const { updateSpecCate, selectCate } = specCateSlice.actions;
export default specCateSlice.reducer;

import axios from "axios";
import { toast } from "react-toastify";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
  withCredentials: false,
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    config.headers!.Authorization = token ? `Bearer ${token}` : "";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function ({ data: responseData }) {
    const { status, message = "API error!", code } = responseData;
    if (code === 401) toast.error("UnAuthenticate!");
    if (code === 403) toast.warn("Permission denied!");
    if (!status) return Promise.reject(message);

    return responseData;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type SideBarState = {
  selectedNav: string;
};

const initialState = { selectedNav: "" } as SideBarState;

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    selectNavbar(state, action: PayloadAction<{ nav: string }>) {
      state.selectedNav = action.payload.nav;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(selectNav.fulfilled, (state: SideBarState, action) => {
  //     state.selectedNav = 'abc';
  //   });
  // },
});

export const { selectNavbar } = sidebarSlice.actions;
export default sidebarSlice.reducer;

import { configureStore } from "@reduxjs/toolkit";

import defaultFormReducer from "./default-form/reducer";
import sibebarReducer from "./navbar/reducer";
import productReducer from "./product/reducer";
import specValueReducer from "./spec-value/reducer";
import specReducer from "./spec/reducer";
import specCateReducer from "./specCate/reducer";

export const store = configureStore({
  devTools: false,
  reducer: {
    sidebar: sibebarReducer,
    specCate: specCateReducer,
    defaultForm: defaultFormReducer,
    product: productReducer,
    spec: specReducer,
    specValue: specValueReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Product, ProductState } from "@src/types/product";
import { List } from "src/types/common";
import {
  cloneSpec,
  getDetailProduct,
  getListProduct,
  updateProduct,
} from "./action";

const initialState = {
  loading: false,
  listProduct: [],
  totalPage: 0,
  currentProduct: null,
} as ProductState;

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getListProduct.fulfilled,
      (state: ProductState, action: PayloadAction<List<Product> | null>) => {
        const { rows = [], totalPage = 0 } = action.payload || {};
        state.listProduct = rows;
        state.totalPage = totalPage;
      }
    );
    builder.addCase(
      getDetailProduct.fulfilled,
      (state: ProductState, action: PayloadAction<Product | null>) => {
        state.currentProduct = action.payload;
      }
    );
    builder.addCase(
      updateProduct.fulfilled,
      (state: ProductState, action: PayloadAction<Product | null>) => {
        if (!action.payload) return state;
        return {
          ...state,
          listProduct: state.listProduct.map((product) =>
            product.id !== action.payload?.id ? product : action.payload
          ),
          currentProduct: action.payload,
        };
      }
    );
    builder.addCase(
      cloneSpec.fulfilled,
      (state: ProductState, action: PayloadAction<Product | null>) => {
        if (!action.payload) return state;
        return {
          ...state,
          listProduct: state.listProduct.map((product) =>
            product.id !== action.payload?.id ? product : action.payload
          ),
          currentProduct: action.payload,
        };
      }
    );
  },
});

// export const {} = defaultFormSlice.actions;
export default productSlice.reducer;

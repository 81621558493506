import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "src/api";
import { List } from "src/types/common";
import { PayLoadUpdateProduct, Product, ProductQuery } from "src/types/product";
import { removeEmptyKey } from "src/utils";

export const getListProduct = createAsyncThunk(
  `product/getListProduct`,
  async (query: ProductQuery): Promise<List<Product> | null> => {
    try {
      const { page = 1, pageLength = 10, ...rest } = query;
      const { data } = await api.get<List<Product>>("/products", {
        params: {
          page,
          pageLength,
          ...removeEmptyKey(rest),
        },
      });
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const getDetailProduct = createAsyncThunk(
  `product/getDetailProduct`,
  async (id: string): Promise<Product | null> => {
    try {
      const { data } = await api.get<Product>(`/products/${id}`);
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const updateProduct = createAsyncThunk(
  `product/updateProduct`,
  async (payload: PayLoadUpdateProduct): Promise<Product | null> => {
    try {
      const { data } = await api.put<Product>("/products", payload);
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const cloneSpec = createAsyncThunk(
  `product/cloneSpec`,
  async ({
    idForm,
    idProduct,
  }: {
    idProduct: string;
    idForm: string;
  }): Promise<Product | null> => {
    try {
      const { data } = await api.patch<Product>(
        `/products/${idProduct}/form/${idForm}`
      );
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { DefaultForm, DefaultFormState } from "@src/types/default-form";
import { List } from "src/types/common";
import {
  createForm,
  deleteForm,
  getDetailDefaultForm,
  getListDefaultForm,
  updateForm,
} from "./action";

const initialState = {
  loading: false,
  listForm: [],
  totalPage: 0,
  currentForm: null,
} as DefaultFormState;

const defaultFormSlice = createSlice({
  name: "defaultForm",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getListDefaultForm.fulfilled,
      (
        state: DefaultFormState,
        action: PayloadAction<List<DefaultForm> | null>
      ) => {
        const { rows = [], totalPage = 0 } = action.payload || {};
        state.listForm = rows;
        state.totalPage = totalPage;
      }
    );
    builder.addCase(
      getDetailDefaultForm.fulfilled,
      (state: DefaultFormState, action: PayloadAction<DefaultForm | null>) => {
        state.currentForm = action.payload;
      }
    );
    builder.addCase(
      updateForm.fulfilled,
      (state: DefaultFormState, action: PayloadAction<DefaultForm | null>) => {
        if (!action.payload) return state;
        return {
          ...state,
          listForm: state.listForm.map((form) =>
            form.id !== action.payload?.id ? form : action.payload
          ),
          currentForm: action.payload,
        };
      }
    );
    builder.addCase(
      createForm.fulfilled,
      (state: DefaultFormState, action: PayloadAction<DefaultForm | null>) => {
        if (!action.payload) return state;
        return {
          ...state,
          listForm: [...state.listForm, action.payload],
        };
      }
    );
    builder.addCase(
      deleteForm.fulfilled,
      (state: DefaultFormState, action: PayloadAction<string | null>) => {
        if (!action.payload) return state;
        return {
          ...state,
          listForm: state.listForm.filter((form) => form.id !== action.payload),
        };
      }
    );
  },
});

// export const {} = defaultFormSlice.actions;
export default defaultFormSlice.reducer;

import classsName from "classnames";
import { lazy, Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "./api";
import "./App.scss";
import Loading from "./components/Loading";
import { dynamicPage } from "./route";
import { useAppSelector } from "./store/hook";

const SideBar = lazy(() => import("./components/SideBar"));

type AppState = {
  showNav: boolean;
  loading: boolean;
};

const App = () => {
  const navigate = useNavigate();
  const nav: string = useAppSelector<string>(
    (state) => state.sidebar.selectedNav
  );

  const [state, setState] = useState<AppState>({
    showNav: true,
    loading: false
  });

  useEffect(() => {
    (async () => {
      setState((prev) => ({ ...prev, loading: true }))
      let user = null;
      try {
        const token = localStorage.getItem("token");

        if (token) {
          user = await api.get(`${process.env.REACT_APP_BASE_URL}auth/info`)
        } else {
          user = await api.get(`${process.env.REACT_APP_AUTH_SVC}/auth/profile`, { withCredentials: true })
        }

        const { data } = user;

        if (!data) navigate("login");
        localStorage.setItem("user", JSON.stringify(data));

      } catch (error) {
        console.log(error);
        navigate("login");
      } finally {
        setState((prev) => ({ ...prev, loading: false }))
      }
    })()
  }, [navigate]);

  const handleSideBarNav = () => {
    setState((prev) => ({
      ...prev,
      showNav: !prev.showNav,
    }));
  };

  const marginPage = classsName("main", {
    "!ml-64 !w-[calc(100%-16rem)]": state.showNav,
  });

  return (
    <div className="flex items-center overflow-hidden">
      {state.loading ? <Loading /> : <>
        <SideBar handleSideBarNav={handleSideBarNav} showNav={state.showNav} />
        <div className={marginPage}>
          <Suspense fallback={<Loading />}>{dynamicPage(nav)}</Suspense>
        </div>
        <ToastContainer
          className="absolute z-10"
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
        />
      </>
      }
    </div>
  );
};

export default App;

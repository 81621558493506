import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  PayloadCreateSpec,
  PayloadUpdateSpec,
  Spec,
  SpecQuery,
} from "@src/types/spec";
import { toast } from "react-toastify";
import api from "src/api";
import { List } from "src/types/common";
import { updateSpec } from "./reducer";
import { removeEmptyKey } from "src/utils";

export const getListSpec = createAsyncThunk(
  `spec/getListSpec`,
  async (query: SpecQuery): Promise<List<Spec> | null> => {
    try {
      const { page = 1, pageLength = 10, ...rest } = query;
      const { data } = await api.get<List<Spec>>("/specification", {
        params: {
          page,
          pageLength,
          ...removeEmptyKey(rest),
        },
      });
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const updateSpecRequest = createAsyncThunk(
  `spec/updateSpec`,
  async (payload: PayloadUpdateSpec, { dispatch }): Promise<Spec | null> => {
    try {
      const { data } = await api.put<Spec>("/specification", payload);
      if (data) dispatch(updateSpec(data));
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const createSpecRequest = createAsyncThunk(
  `spec/createSpec`,
  async (payload: PayloadCreateSpec): Promise<Spec | null> => {
    try {
      const { data } = await api.post<Spec>("/specification", payload);
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const deleteSpec = createAsyncThunk(
  `spec/deleteSpec`,
  async (id: string): Promise<string | null> => {
    try {
      await api.delete(`/specification/${id}`);
      return id;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

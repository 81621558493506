import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import { routes } from "./route";
import { store } from "./store/store";

if (process.env.REACT_APP_APP_ENV === "production") disableReactDevTools();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>{routes()}</Provider>
  // </React.StrictMode>
);

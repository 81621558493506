import { createAsyncThunk } from "@reduxjs/toolkit";
import { List } from "@src/types/common";
import { SpecCate, SpecCateQuery } from "@src/types/specCate";
import { toast } from "react-toastify";
import api from "src/api";
import { updateSpecCate } from "./reducer";

export const getListSpecCate = createAsyncThunk(
  `specCate/getListSpecCate`,
  async (query: SpecCateQuery): Promise<List<SpecCate> | null> => {
    try {
      const { page = 1, pageLength = 10, ...rest } = query;
      const { data } = await api.get<List<SpecCate>>("/spec-category", {
        params: {
          page,
          pageLength,
          ...rest,
        },
      });
      return data;
    } catch (err: any) {
      // toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const updateSpecCateRequest = createAsyncThunk(
  `specCate/updateSpecCate`,
  async (
    payload: Partial<SpecCate>,
    { dispatch }
  ): Promise<SpecCate | null> => {
    try {
      const { data } = await api.put<SpecCate>("/spec-category", payload);
      if (data) dispatch(updateSpecCate(data));
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const createSpecCateRequest = createAsyncThunk(
  `specCate/createSpecCate`,
  async (payload: Partial<SpecCate>): Promise<SpecCate | null> => {
    try {
      const { data } = await api.post<SpecCate>("/spec-category", payload);
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const deleteSpecCate = createAsyncThunk(
  `specCate/deleteSpecCate`,
  async (id: string): Promise<string | null> => {
    try {
      await api.delete(`/spec-category/${id}`);
      return id;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  PayloadCreateSpecValue,
  PayloadUpdateSpecValue,
  SpecValue,
  SpecValueQuery,
} from "@src/types/specValue";
import { toast } from "react-toastify";
import api from "src/api";
import { List } from "src/types/common";
import { removeEmptyKey } from "src/utils";
import { updateSpecValue } from "./reducer";

export const getListSpecValue = createAsyncThunk(
  `specValue/getListSpec`,
  async (query: SpecValueQuery): Promise<List<SpecValue> | null> => {
    try {
      const { page = 1, pageLength = 10, ...rest } = query;
      const { data } = await api.get<List<SpecValue>>("/spec-value", {
        params: {
          page,
          pageLength,
          ...removeEmptyKey(rest),
        },
      });
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const updateSpecValueRequest = createAsyncThunk(
  `specValue/updateSpecValue`,
  async (
    payload: PayloadUpdateSpecValue,
    { dispatch }
  ): Promise<SpecValue | null> => {
    try {
      const { data } = await api.put<SpecValue>("/spec-value", payload);
      if (data) dispatch(updateSpecValue(data));
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const createSpecValueRequest = createAsyncThunk(
  `specValue/createSpecValue`,
  async (payload: PayloadCreateSpecValue): Promise<SpecValue | null> => {
    try {
      const { data } = await api.post<SpecValue>("/spec-value", payload);
      return data;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

export const deleteSpecValue = createAsyncThunk(
  `specValue/deleteSpecValue`,
  async (id: string): Promise<string | null> => {
    try {
      await api.delete(`/spec-value/${id}`);
      return id;
    } catch (err: any) {
      toast.error(err.message || "API error!");
      return null;
    }
  }
);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Spec, SpecState } from "@src/types/spec";
import { List } from "src/types/common";
import { createSpecRequest, deleteSpec, getListSpec } from "./action";

const initialState = {
  loading: false,
  listSpec: [],
  totalPage: 0,
  currentSpec: null,
} as SpecState;

const specSlice = createSlice({
  name: "spec",
  initialState,
  reducers: {
    selectSpec: (state: SpecState, action: PayloadAction<Spec | null>) => {
      return {
        ...state,
        currentSpec: action.payload,
      };
    },
    updateSpec: (state: SpecState, action: PayloadAction<Partial<Spec>>) => {
      return {
        ...state,
        listSpec: state.listSpec.map((spec: Spec) => {
          const { id } = action.payload;
          if (spec.id !== id) return spec;
          return { ...spec, ...action.payload };
        }),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListSpec.pending, (state: SpecState) => {
      state.loading = true;
    });
    builder.addCase(
      getListSpec.fulfilled,
      (state: SpecState, action: PayloadAction<List<Spec> | null>) => {
        state.loading = false;
        const { rows = [], totalPage = 0 } = action.payload || {};
        state.listSpec = rows;
        state.totalPage = totalPage;
      }
    );
    builder.addCase(getListSpec.rejected, (state: SpecState) => {
      state.loading = false;
    });
    builder.addCase(
      createSpecRequest.fulfilled,
      (state: SpecState, action: PayloadAction<Spec | null>) => {
        if (!action.payload) return state;
        return {
          ...state,
          listSpec: [...state.listSpec, action.payload],
        };
      }
    );
    builder.addCase(
      deleteSpec.fulfilled,
      (state: SpecState, action: PayloadAction<string | null>) => {
        if (!action.payload) return state;
        return {
          ...state,
          listSpec: state.listSpec.filter((spec) => spec.id !== action.payload),
        };
      }
    );
  },
});

export const { selectSpec, updateSpec } = specSlice.actions;
export default specSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SpecValue, SpecValueState } from "@src/types/specValue";
import { List } from "src/types/common";
import {
  createSpecValueRequest,
  deleteSpecValue,
  getListSpecValue,
} from "./action";

const initialState = {
  loading: false,
  listSpecValue: [],
  totalPage: 0,
  currentSpecValue: null,
} as SpecValueState;

const specSlice = createSlice({
  name: "specValue",
  initialState,
  reducers: {
    selectSpecValue: (
      state: SpecValueState,
      action: PayloadAction<SpecValue | null>
    ) => {
      return {
        ...state,
        currentSpecValue: action.payload,
      };
    },
    updateSpecValue: (
      state: SpecValueState,
      action: PayloadAction<Partial<SpecValue>>
    ) => {
      return {
        ...state,
        listSpecValue: state.listSpecValue.map((specValue: SpecValue) => {
          const { id } = action.payload;
          if (specValue.id !== id) return specValue;
          return { ...specValue, ...action.payload };
        }),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListSpecValue.pending, (state: SpecValueState) => {
      state.loading = true;
    });
    builder.addCase(
      getListSpecValue.fulfilled,
      (
        state: SpecValueState,
        action: PayloadAction<List<SpecValue> | null>
      ) => {
        state.loading = false;
        const { rows = [], totalPage = 0 } = action.payload || {};
        state.listSpecValue = rows;
        state.totalPage = totalPage;
      }
    );
    builder.addCase(getListSpecValue.rejected, (state: SpecValueState) => {
      state.loading = false;
    });
    builder.addCase(
      createSpecValueRequest.fulfilled,
      (state: SpecValueState, action: PayloadAction<SpecValue | null>) => {
        if (!action.payload) return state;
        return {
          ...state,
          listSpecValue: [...state.listSpecValue, action.payload],
        };
      }
    );
    builder.addCase(
      deleteSpecValue.fulfilled,
      (state: SpecValueState, action: PayloadAction<string | null>) => {
        if (!action.payload) return state;
        return {
          ...state,
          listSpecValue: state.listSpecValue.filter(
            (value) => value.id !== action.payload
          ),
        };
      }
    );
  },
});

export const { selectSpecValue, updateSpecValue } = specSlice.actions;
export default specSlice.reducer;
